<div id="toastcontainer" class="toast-container">
 
  <div @toastTrigger *ngFor="let toast of toasts$ | async | slice : 0 : 9;
  let index=index;trackBy:trackBy" >
    <mat-progress-bar mode="determinate" value="100" [color]="toast.type == toastTypes.SUCCESS?'primary':'warn'" color="warn" ></mat-progress-bar>
    <div class="toast"
    [ngClass]="{'bg-success':toast.type == toastTypes.SUCCESS,'bg-info':toast.type == toastTypes.WARNING,'bg-danger':toast.type == toastTypes.DANGER}"
    [attr.data-test]="'toast-'+(index+1)" role="alert" aria-live="assertive">
      <img *ngIf="toast.type == toastTypes.DANGER" src="./assets/icons/error.svg" alt="error">
      <img *ngIf="toast.type == toastTypes.SUCCESS" src="./assets/icons/success.svg" alt="success">
     <div class="header-container">
       <div class="header">
         <span *ngIf="toast.type == toastTypes.SUCCESS">{{'success' | translate}}</span>
         <span *ngIf="toast.type == toastTypes.DANGER">{{'error' | translate}}</span>
         <span *ngIf="toast.type == toastTypes.WARNING">{{'warn' | translate}}</span>
         <button class="close-btn" [ngClass]="{'btn-success':toast.type == toastTypes.SUCCESS,'btn-danger':toast.type == toastTypes.DANGER}"
          mat-icon-button (click)="close(index)"><mat-icon>close</mat-icon></button>
       </div>
       <p class="body">{{toast.message}}</p>
     </div>
    </div>
 
  </div>
</div>
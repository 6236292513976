import { SelectOption } from "../components/forms/config/forms.config";

export const timeZones:  SelectOption[] = [
    { value: "GMT", label: "Greenwich Mean Time (GMT)" },
    { value: "EST", label: "Eastern Standard Time (EST)" },
    { value: "CST", label: "Central Standard Time (CST)" },
    { value: "PST", label: "Pacific Standard Time (PST)" },
    { value: "JST", label: "Japan Standard Time (JST)" },
    { value: "IST", label: "Indian Standard Time (IST)" },
    { value: "AEST", label: "Australian Eastern Standard Time (AEST)" },
    { value: "EET", label: "Eastern European Time (EET)" },
    { value: "AST", label: "Arabian Standard Time (AST)" },
    { value: "SAST", label: "South African Standard Time (SAST)" }
]
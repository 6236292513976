import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TOAST_TYPE, ToastService } from 'src/app/shared/services/toast.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { v4 as uuidv4 } from 'uuid';
import { MatMenuModule } from '@angular/material/menu';
import { TranslationService } from 'src/app/shared/services/translation.service';
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.value === control.parent?.value.newPassword
    ? null
    : { PasswordNoMatch: true };
};
@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    SharedModule
  ],
})
export class NewPasswordComponent implements OnInit {
  public resetPasswordForm!: FormGroup;
  public loading: boolean = false;
  @ViewChild('password') password!: ElementRef<HTMLElement>
  seenPassword:boolean=false
  @ViewChild('confirmPassword') confirmPassword!: ElementRef<HTMLElement>
  seenConfirmPassword:boolean=false
  constructor(
    private toastService : ToastService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public themeService: ThemeService,
    public translationService : TranslationService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }
  /**
   * initailize login form
   */
  private initializeForm(): void {
    this.resetPasswordForm = new FormGroup({
      newPassword:new FormControl(null,[Validators.required]),
      confirmPassword:new FormControl(null,[Validators.required,confirmPasswordValidator])
    })
    this.resetPasswordForm.get('newPassword')?.valueChanges.subscribe((val)=>{
      this.resetPasswordForm.get('confirmPassword')?.reset()
    })
  }

  /**
   * send credentials to server
   */
  public login() {
    if(this.resetPasswordForm.invalid){
      this.resetPasswordForm.markAllAsTouched()
      return;
    }
    this.loading = true;
    let data= {
     newPassword: this.resetPasswordForm.value.newPassword,
     otp: this.authService.otpForResetPassword,
     email:this.authService.resetPasswordEmail
    }
    this.authService.resetPassword(data).subscribe({
      next: (data) => {
        this.authService.resetPasswordEmail=''
        this.authService.otpForResetPassword=0
        const res : any =data
        this.authService.setUser(res);
        this.authService.setAuthToken(res?.accessToken);
        this.router.navigate(['/dashboard/statistics'], { replaceUrl: true });
      },
      error: (error) => {
        this.loading = false;
        this.toastService.addToasts({message:(error.message || error) ,type:TOAST_TYPE.DANGER,id:Date.now()})
      },
    });
  }
  enterPress(event:KeyboardEvent){
    if(event.key=="Enter"){
     this.login() 
    }
   }

  isInvalid(formGroup: FormGroup, formControlName: string) {
    return formGroup.get(formControlName)?.invalid &&
      formGroup.get(formControlName)?.touched
  }

  getErrorMessage(formGroup: FormGroup, controlName: string) {
    let errors: any = [];
    const errorsObj : any = formGroup.get(controlName)!.errors;
    Object.keys(formGroup.get(controlName)?.errors || []).forEach((key) => {
      if (key == 'required') {
        errors.push('required');
      }
      else if(key=='PasswordNoMatch') {
        errors.push('did_not_match_with_new_password');
      }
    });
    return errors.join(', ');
  }

  changeSeen(){
    if(this.seenPassword)  this.password.nativeElement.setAttribute("type","password")
    else  this.password.nativeElement.setAttribute("type","text")
    this.seenPassword =  !this.seenPassword
  }

  changeConfirmSeen(){
    if(this.seenConfirmPassword)  this.confirmPassword.nativeElement.setAttribute("type","password")
    else  this.confirmPassword.nativeElement.setAttribute("type","text")
    this.seenConfirmPassword =  !this.seenConfirmPassword
  }
}

<div class="container" (keyup)="enterPress($event)">
  <div class="header">
    <img class="logo" src="./assets/images/FLogo.svg" >
  <div class="side-button">
    <div class="translate-menu" [matMenuTriggerFor]="menu">
      <img src="./assets/icons/language.svg">
      <span>{{ translationService.default.label }}</span>
    </div>
    <button class="back-button" [routerLink]="'/dashboard'"> <img style="margin-right: 5px;" src="./assets/icons/arrow-left.svg"> {{ 'back' | translate }}</button>
  </div>
  <mat-menu #menu="matMenu" >
    <ng-container *ngFor="let language of translationService.availabeLanguages">
      <button mat-menu-item (click)="translationService.switchLanguage(language.value)">{{ language.label }}</button>
    </ng-container>
  </mat-menu>
  </div>
  <div class="card" [formGroup]="sendEmailForm">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="center-text card-title">
      <p class="title">{{'reset_password' | translate}}</p>
      <span>{{'To Confirm Your Identity We\'ll Send You A Verification Code To Your Email.' | translate}}</span>
    </div>
    <div class="bold-text label">{{ "email_address" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="email" formControlName="email" matInput [placeholder]="'email_address' | translate">
      <mat-error *ngIf="isInvalid(sendEmailForm,'email')">{{ "email_address" | translate }} {{ getErrorMessage(sendEmailForm,'email')  | translate }}</mat-error>
    </mat-form-field>
    <button class="send-button" [disabled]="loading"
    (click)="sendEmail()">{{ 'send' | translate }}</button>
  </div>
</div>
<span class="bottom-center">© Copyright 2024, All Rights Reserved.</span>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TOAST_TYPE, ToastService } from 'src/app/shared/services/toast.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { v4 as uuidv4 } from 'uuid';
import { MatMenuModule } from '@angular/material/menu';
import { TranslationService } from 'src/app/shared/services/translation.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    SharedModule
  ],
})
export class LoginComponent implements OnInit {
  public singInForm!: FormGroup;
  public loading: boolean = false;
  @ViewChild('password') password!: ElementRef<HTMLElement>
  seenPassword:boolean=false
  constructor(
    private toastService : ToastService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private messagingService: MessagingService,
    public themeService: ThemeService,
    public translationService : TranslationService
  ) { }

  ngOnInit(): void {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage() 
    localStorage.setItem("deviceName",uuidv4())
    this.initializeForm();
  }
  /**
   * initailize login form
   */
  private initializeForm(): void {
    this.singInForm = this.formBuilder.group({
      provider: [null, [Validators.required,Validators.email]],
      password: [null, Validators.required]
    });
  }

  /**
   * send credentials to server
   */
  public login() {
    if(this.singInForm.invalid){
      this.singInForm.markAllAsTouched()
      return
    }

    this.loading = true;
    this.authService.sendOTP(this.singInForm.value).subscribe({
      next: () => {
        this.authService.saveLoginInfo = this.singInForm.value
        this.router.navigate(['/otp'], { replaceUrl: true });
      },
      error: (error) => {
        this.loading = false;
        this.toastService.addToasts({message:(error.message || error) ,type:TOAST_TYPE.DANGER,id:Date.now()})
      },
    });
  }

  enterPress(event:KeyboardEvent){
    if(event.key=="Enter"){
     this.login() 
    }
   }

  isInvalid(formGroup: FormGroup, formControlName: string) {
    return formGroup.get(formControlName)?.invalid &&
      formGroup.get(formControlName)?.touched
  }

  getErrorMessage(formGroup: FormGroup, controlName: string) {
    let errors: any = [];
    const errorsObj : any = formGroup.get(controlName)!.errors;
    Object.keys(formGroup.get(controlName)?.errors || []).forEach((key) => {
      if (key == 'required') {
        errors.push('required');
      }
      else if(key == 'email'){
        errors.push('is_invalid_email')
      }
    });
    return errors.join(', ');
  }

  changeSeen(){
    if(this.seenPassword)  this.password.nativeElement.setAttribute("type","password")
    else  this.password.nativeElement.setAttribute("type","text")
    this.seenPassword =  !this.seenPassword
  }
}

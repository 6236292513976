import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@Component({
  selector: 'app-page-not-found-auth',
  templateUrl: './page-not-found-auth.component.html',
  styleUrls: ['./page-not-found-auth.component.scss'],
  standalone: true,
  imports:[MatButtonModule,
           MatIconModule,
           MatMenuModule,
           TranslateModule,
           SharedModule,
           RouterModule]
})
export class PageNotFoundAuthComponent {
  constructor(public translationService: TranslationService){}

}

import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { AppError } from 'src/app/models/responses/app-error';
import { BaseResponse } from 'src/app/models/responses/base.response';
import { environment } from 'src/environments/environment';

export class CommonDataService {
  constructor(private endPoint: string) { }

  protected getFullUrl(resourceName: string) {
    let url = `${environment.apiUrl}`;
    let endpoint = this.endPoint;
    if (endpoint) {
      url = `${url}/${endpoint}`
    }
    if (resourceName) {
      url = `${url}/${resourceName}`
    }
    return url;
  }

  protected emitData<T>(response: BaseResponse<T>): T {
    return response.data;
  }

  protected handleError(error: Error): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      return throwError(() => new AppError(error.statusText));
    }
    return throwError(() => new AppError(error.message));
  }

  public parseToFormData(object: any): FormData {
    const keys = Object.keys(object);
    const formData = new FormData();

    for (const key of keys) {
      
      if(typeof object[key] == 'boolean'){
        object[key] = object[key]?1:0
      }
      
      if (object[key] instanceof moment) {
        object[key] = object[key].format('MM-DD-YYYY');
      }

      if (object[key] instanceof Date) {
        object[key] = moment(object[key]).toISOString();
      }

      if (Array.isArray(object[key])) {
        const list = [...object[key]];
        for (let i = 0; i < list.length; i++) {
          if (list[i] instanceof File)
            formData.append(`${key}`, list[i]);

          else
            formData.append(`${key}[${i}]`, list[i] instanceof Object ? JSON.stringify(list[i]) : list[i]);
        }
        continue;
      }

      if (object[key] instanceof Object && formData.get(key) == null){
        formData.append(key,JSON.stringify(object[key]));
      }


      if (object[key] !== null && typeof object[key] !== 'undefined' && formData.get(key) == null) {
          formData.append(key, object[key]);
      }
    }
    return formData;
  }
}

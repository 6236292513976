import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { PageTitleService } from './shared/services/page.title.service';
import { ThemeService } from './shared/services/theme.service';
import { TranslationService } from './shared/services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public loading: boolean = true;

  constructor(
    public readonly translationService: TranslationService,
    readonly pageTitleService: PageTitleService,
    public readonly themeService: ThemeService,
    private router: Router,
  ) {
    this.subscribeRouterEvents();
  }

  /**
   * subscribe router when navigation state changed
   */
  private subscribeRouterEvents() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd), take(1))
      .subscribe((e) => {
        this.loading = false;
      });
  }
}

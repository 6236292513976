import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs'
@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    constructor(private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messages.subscribe(
            (_messaging: any) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }
    requestPermission() {
  
        this.angularFireMessaging.requestToken.subscribe({
           next: (token: any) => {
              // console.log("token: "+token);
               localStorage.setItem('firebaseToken', token);
            },
            error:(err: any) => {
              //  console.error('Unable to get permission to notify.', err);
            }
        });
    }

    requestNotification(){
        this.angularFireMessaging.requestPermission.subscribe(res=>{
          //  console.log(res);
            
        })
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload:any) => {
              //console.log("new message received. ", payload);
                this.currentMessage.next(payload);
            })
    }
}
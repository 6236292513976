<div class="not-found">
  <div class="header">
    <img class="logo" src="../../../assets/images/FLogo.svg" >
  <div class="side-button">
    <div class="translate-menu" [matMenuTriggerFor]="menu">
      <img src="../../../assets/icons/language.svg">
      <span>{{ translationService.default.label }}</span>
    </div>
    <button class="back-button" [routerLink]="'/dashboard'"> <img style="margin-right: 5px;" src="../../../assets/icons/arrow-left.svg"> {{ 'back' | translate }}</button>
  </div>
  <mat-menu #menu="matMenu" >
    <ng-container *ngFor="let language of translationService.availabeLanguages">
      <button mat-menu-item (click)="translationService.switchLanguage(language.value)">{{ language.label }}</button>
    </ng-container>
  </mat-menu>
  </div>
  <div class="content">
    <img src="../../../assets//images//404.svg">
    <p class="title">{{ 'page_not_found' | translate }}</p>
    <span>{{ 'The Page You Are Looking For Might Have Been Removed Had Its Name Changed Or Temporarily Unavailable' | translate}}</span>
    <button class="content-button" [routerLink]="'/dashboard'">{{ 'go_back' | translate }}</button>
  </div>
</div>
<span class="bottom-center">© Copyright 2024, All Rights Reserved.</span>
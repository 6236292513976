import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TOAST_TYPE, ToastService } from 'src/app/shared/services/toast.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { v4 as uuidv4 } from 'uuid';
import { MatMenuModule } from '@angular/material/menu';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { NgOtpInputModule } from  'ng-otp-input';
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    SharedModule,
    NgOtpInputModule
  ],
})
export class VerifyComponent implements OnInit {
  public loading: boolean = false;
  code= new FormControl(null,[Validators.required])
  constructor(
    private toastService : ToastService,
    public authService: AuthService,
    private router: Router,
    public themeService: ThemeService,
    public translationService : TranslationService,
    
  ) { }

  ngOnInit(): void {
  }


  /**
   * send credentials to server
   */
  public verify() {
    if (this.code.invalid) {
      this.code.markAllAsTouched()
      return;
    }
    this.loading = true;
    let otp = this.code.value
    let data = {
      provider:this.authService.resetPasswordEmail,
      otp: Number(otp),
    }
    this.loading = true;
    this.authService.otpChecker(data).subscribe({
      next: () => {
        this.authService.otpForResetPassword = Number(otp)
        this.router.navigate(['/reset-password'])
      },
      error: (error) => {
        this.loading = false;
        this.toastService.addToasts({message:(error.message || error) ,type:TOAST_TYPE.DANGER,id:Date.now()})
      },
    });
  }
  
  isInvalid() {
    return this.code?.invalid &&
    this.code?.touched
  }

 enterPress(event:KeyboardEvent){
    if(event.key=="Enter"){
     this.verify() 
    }
   }


  resendCode(){
    this.loading = true;
    this.authService.sendCodeToResetPassword({email:this.authService.resetPasswordEmail}).subscribe({
      next: () => {
        this.loading = false;
        this.toastService.addToasts({message:this.translationService.getTranslate('we_send_new_otp_please_check_your_email') ,type:TOAST_TYPE.SUCCESS,id:Date.now()})
      },
      error: (error) => {
        this.loading = false;
        this.toastService.addToasts({message:(error.message || error) ,type:TOAST_TYPE.DANGER,id:Date.now()})
      },
    });
  }
}

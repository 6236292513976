<div class="container" (keyup)="enterPress($event)">
  <div class="header">
    <img class="logo" src="./assets/images/FLogo.svg" >
  <div class="side-button">
    <div class="translate-menu" [matMenuTriggerFor]="menu">
      <img src="./assets/icons/language.svg">
      <span>{{ translationService.default.label }}</span>
    </div>
    <button class="back-button" [routerLink]="'/dashboard'"> <img style="margin-right: 5px;" src="./assets/icons/arrow-left.svg"> {{ 'back' | translate }}</button>
  </div>
  <mat-menu #menu="matMenu" >
    <ng-container *ngFor="let language of translationService.availabeLanguages">
      <button mat-menu-item (click)="translationService.switchLanguage(language.value)">{{ language.label }}</button>
    </ng-container>
  </mat-menu>
  </div>
  <div class="card">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="center-text card-title">
      <p class="title">{{'verify_your_account' | translate}}</p>
      <span>{{'Enter The Code We Just Sent To' | translate}} {{this.authService.saveLoginInfo?.provider}}</span>
    </div>

    <div class="code-section">
      <ng-otp-input [formCtrl]="code"  [config]="{length:5,allowNumbersOnly:true}"
      ></ng-otp-input>
  </div>
  <div class="error-div">
    <mat-error *ngIf="isInvalid()">{{ "code" | translate }} {{ 'required'  | translate }}</mat-error>
  </div>



   <div class="center-text">
    <span>{{'Didn\'t Receive Code ?' | translate}}</span><span (click)="resendCode()" class="primary-color pointer">{{'resend_code' | translate}}</span>
  </div>
    <button class="send-button" [disabled]="loading"
    (click)="verify()">{{ 'verify' | translate }}</button>
  </div>
</div>
<span class="bottom-center">© Copyright 2024, All Rights Reserved.</span>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TOAST_TYPE, ToastService } from 'src/app/shared/services/toast.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { v4 as uuidv4 } from 'uuid';
import { MatMenuModule } from '@angular/material/menu';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { FormDataService } from 'src/app/shared/components/forms/service/form.service';
import { User } from 'src/app/models/data/user.model';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { SelectOption } from 'src/app/shared/components/forms/config/forms.config';
import { timeZones } from 'src/app/shared/constant/time-zones';
import { currencyCodes } from 'src/app/shared/constant/currency-codes';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    SharedModule,
    MatIconModule,
    MatSelectModule
  ],
  providers:[FormDataService]
})
export class RedirectComponent implements OnInit {
  hashId:string=''
  showForm:boolean=false
  public companyInfoForm!: FormGroup;
  public loading: boolean = false;
  allDay:string[]=['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
  startDay:string[]=[]
  months:string[] = ['January - December','February - January','March - February','April - March','May - April','June - May','July - June','August - July','September - August','October - September','November - October','December - November'];
  allCurrency :SelectOption[] = currencyCodes
  timeZones : SelectOption[]  = timeZones
  constructor(
    private toastService : ToastService,
    private authService: AuthService,
    private router: Router,
    private messagingService: MessagingService,
    public themeService: ThemeService,
    public translationService : TranslationService,
    private formDataService: FormDataService,
    private route : ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.hashId= this.route.snapshot.params['id']
    this.authService.apiKey = this.hashId;
    this.getUserAuth()
    this.initializeForm()
  }

   /**
   * initailize login form
   */
   private initializeForm(): void {
    this.companyInfoForm = new FormGroup({
      companyName: new FormControl(null, [Validators.required]),
      companyLocation: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      currency: new FormControl(null, [Validators.required]),
      timeZone: new FormControl(null, [Validators.required]),
      language: new FormControl(null, [Validators.required]),
      fiscalYear: new FormControl(null, [Validators.required]),
      startDate: new FormControl(null, [Validators.required]),
      prefix:new FormControl(null, [Validators.required]),
      startingNumber:new FormControl(null, [Validators.required,Validators.pattern('[0-9]{1,15}')])
    })
  }


  getUserAuth(){
    this.formDataService.get<User>('auth/redirect').subscribe({
     next:(res)=>{
        this.authService.userLogin = res
        this.showForm = true
     },
     error:(err)=>{
        this.toastService.addToasts({message:err.message,id:Date.now(),type:TOAST_TYPE.DANGER})
     }
    })
  }

   /**
   * send credentials to server
   */
   public sendInfo() {
    if(this.companyInfoForm.invalid){
      this.companyInfoForm.markAllAsTouched()
      return
    }
    this.loading = true;
    let data: FormData = new FormData();
    data.append('name', this.companyInfoForm.value.companyName);
    data.append('location', this.companyInfoForm.value.companyLocation);
    data.append('state', this.companyInfoForm.value.state);
    data.append('language', this.companyInfoForm.value.language);
    data.append('timeZone', this.companyInfoForm.value.timeZone);
    data.append('currency', this.companyInfoForm.value.currency);
    data.append('fiscalYear', this.companyInfoForm.value.fiscalYear);
    data.append('startDate', this.companyInfoForm.value.startDate);
    data.append('medicalNumberPrefix', this.companyInfoForm.value.prefix);
    data.append('medicalNumberStart', this.companyInfoForm.value.startingNumber);
    this.formDataService.put(`company-profile`, data).subscribe({
    next:(res:any)=>{
      let user : any = this.authService.userLogin
      let companyProfile :any ={} ;
      companyProfile.name = res.name
      companyProfile.location = res.location
      companyProfile.state = res.state
      companyProfile.language = res.language
      companyProfile.timeZone = res.timeZone
      companyProfile.currency = res.currency
      companyProfile.fiscalYear = res.fiscalYear
      companyProfile.startDate = res.startDate
      user.companyProfile = companyProfile
      companyProfile.medicalNumberPrefix = res.medicalNumberPrefix
      companyProfile.medicalNumberStart = res.medicalNumberStart
      this.authService.userLogin = null
      this.authService.setUser(user);
      this.authService.setAuthToken(user?.accessToken);
      this.router.navigate(['/dashboard/statistics'], { replaceUrl: true });      
    },
    error:(err)=>{
       this.toastService.addToasts({message:(err.message || err),id:Date.now(),type:TOAST_TYPE.DANGER})
    }
   })
  }
  enterPress(event:KeyboardEvent){
    if(event.key=="Enter"&&this.showForm){
     this.sendInfo() 
    }
   }
  isInvalid(formGroup: FormGroup, formControlName: string) {
    return formGroup.get(formControlName)?.invalid &&
      formGroup.get(formControlName)?.touched
  }

  getErrorMessage(formGroup: FormGroup, controlName: string) {
    let errors: any = [];
    const errorsObj : any = formGroup.get(controlName)!.errors;
    Object.keys(formGroup.get(controlName)?.errors || []).forEach((key) => {
      if (key == 'required') {
        errors.push('required');
      }
      else if(key == 'email'){
        errors.push('is_invalid_email')
      }
    });
    return errors.join(', ');
  }

  getStartDay(){
    if(this.companyInfoForm.value.fiscalYear){
      let selectedMonth = this.months.indexOf(this.companyInfoForm.value.fiscalYear)
      let numOfDay  = new Date((new Date()).getFullYear(), selectedMonth + 1 , 0).getDate()  
      this.startDay.splice(0,this.startDay.length)
      for(let i=0;i<numOfDay;i++){
        this.startDay.push(this.allDay[i])
      }
      return this.startDay
    }
    else return []
  }


}

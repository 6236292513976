import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-leave-page',
  standalone: true,
  imports: [SharedModule,MatDialogModule,MatButtonModule],
  templateUrl: './leave-page.component.html',
  styleUrls: ['./leave-page.component.scss'],
})
export class LeavePageComponent implements OnInit{
  constructor(public _dialogRef: MatDialogRef<LeavePageComponent>, private themeService : ThemeService){}
  ngOnInit(): void {
    let element = document.querySelector('.cdk-overlay-container') ;
    this.themeService.themeChange$.value ? element?.classList.add("dark-theme") : element?.classList.remove("dark-theme")
  }


}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const baseUrl = 'https://development.nobles.uranuslink.com';

// Test Backend // http://194.233.86.4:8090 

// Test Frontend // http://194.233.86.4:4206

// Dev Backen // https://development.nobles.uranuslink.com

// Dev Frontend // https://development.nobles.uranuslink.com/login

export const environment = {
  production: false,
  apiUrl: `${baseUrl}/api`,
  baseUrl: baseUrl,
  basePhotoUrl: `${baseUrl}/uploads/`,
  perPage: 25,
  firebase: {
    apiKey: 'AIzaSyCpOuRvwwNSgc1LpARgyx0yMCZRxvSWtC0',
    appId: '1:772040368769:web:160164ede494c9e6b02380',
    messagingSenderId: '772040368769',
    projectId: 'clinic-7a799',
    authDomain: 'clinic-7a799.firebaseapp.com',
    storageBucket: 'clinic-7a799.appspot.com',
    measurementId: 'G-M77MX9DF5X',
  },

  bigDialogWidth: '40rem',
  bigDialogMinWidth: '40rem',
  dialogHeight: '100vh',
  smallDialogWidth: '30rem',
  smallDialogMinWidth: '30rem',
  titlePrefix: 'Nobles Clinic',
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibWFobW91ZHRyYWRpbm9zIiwiYSI6ImNrcWdrdzFlNzI1NXUyd3F0djFheWw5N3QifQ.5zgCoO5Hd916E9zLZKbO_A',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

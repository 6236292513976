import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, tap } from "rxjs";
import { User } from "src/app/models/data/user.model";
import { LoginResponse } from "src/app/models/responses/login.response";
import { DataService } from "./http/data.service";

@Injectable({ providedIn: 'root' })
export class AuthService extends DataService {
  public apiKey!: string;
  public userLogin!:any;
  public saveLoginInfo: any;
  public resetPasswordEmail: string='';
  public otpForResetPassword: number = 0
  constructor(
    httpClient: HttpClient,
    private router: Router,
  ) {
    super('', httpClient);
  }

  /**
   * store user object in in local-storage
   * @param {User} user user object
   */
  public setUser(user?: User): void {
    if (!user) return;
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('showFeedback','true')
  }


  /**
   * remove user object from in local-storage
   */
  public removeUser(): void {
    localStorage.clear();
  }

  /**
   * store jwt in in local-storage
   * @param {string} token jwt
   */
  public setAuthToken(token?: string): void {
    localStorage.setItem("token", token || "");
  }

  /**
   * get user object from in local-storage
   * @returns {User | null}
   */
  public getUser(): User | undefined {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user) as User;
    return undefined;
  }

  /**
   * get jwt from in local-storage
   * @returns {string} jwt
   */
  public getAuthToken(): string {
    return localStorage.getItem("token") || "";
  }


    /**
   * get tenant from in local-storage
   * @returns {string} tenant
   */
    public getTenantId(): string {
      const user = JSON.parse(localStorage.getItem("user")!)
      return user?.businessOwner?.tenant?.id ? user?.businessOwner?.tenant?.id : user?.staff?.tenantId;
    }

  public getPermissions() {
    const permissionArray:string[] = []
    const permissions  = this.getUser()?.role?.permissions
    permissions.forEach((element:any) => {
      permissionArray.push(element.name)
    });
    return permissionArray 
  }

  public getRefreshToken(): string{
    const user = JSON.parse(localStorage.getItem("user")!)
    const refreshToken = user.refreshToken
    return refreshToken || ""
  }

  /**
   * get user role
   * @returns {string | undefined}
   */
  public getUserRole(): string | undefined {
    return this.getUser()?.role_id;
  }

  /**
   * check if current user role is admin
   * @returns {boolean} `true` if role is admin
   */
  public isAdmin(): boolean {
    return this.getUserRole() == "admin";
  }

  /**
   * login by send credentials to server
   * @param credentials email and password and otp
   * @returns {Observable<LoginResponse>} login result
   */
  public login(credentials: { provider: string, password: string, otp: number }): Observable<LoginResponse> {
    const firebaseToken = localStorage.getItem("firebaseToken") || '';
    const deviceName = localStorage.getItem("deviceName")
    return this.post<LoginResponse>('auth/login_admin',{...credentials, deviceName : deviceName , deviceToken : firebaseToken}).pipe(
      tap((data: LoginResponse) => {
        const res : any =data
        this.setUser(res);
        this.setAuthToken(res?.accessToken);
      }),
    );
  }


    public sendOTP(credentials: { provider: string, password: string}): Observable<LoginResponse> {
      const firebaseToken = localStorage.getItem("firebaseToken") || '';
      return this.post<LoginResponse>('auth/send_login_otp',{...credentials, deviceToken : firebaseToken})
    }


    public sendCodeToResetPassword(credentials: { email: string}): Observable<LoginResponse> {
      return this.post<LoginResponse>('codes/reset-password-admin',{...credentials})
    }
    
    public otpChecker(credentials: { provider: string, otp: number}): Observable<LoginResponse> {
      return this.post<LoginResponse>('codes/otp_checker',{...credentials})
    }
   
    public resetPassword(credentials: { email: string, otp: number,newPassword:string}): Observable<LoginResponse> {
      const deviceName = localStorage.getItem("deviceName")
      return this.put<LoginResponse>('auth/forgot-password-admin',{...credentials,deviceName:deviceName})
    }
  sendRefreshToken(){
    return this.get<LoginResponse>('refresh').pipe(
      tap((data: LoginResponse) => {
        const res : any =data
        this.setUser(res);
        this.setAuthToken(res?.accessToken);
      }),
    );
  }

  /**
   * send logOut request to server and remove user & token from local-stroage
   */
  logOut(): Observable<void> {
    return this.get<any>('auth/logout').pipe(tap(() => this.removeUser()));
  }

  /**
   * check if user is loggedIn
   * @returns {boolean}
   */
  isLoggedIn(): Observable<boolean> {
    const user = this.getUser();
    if (this.router.url.includes('login') || user) {
      return of(true);
    }
    return of(false);
  }
}

<div class="container" (keyup)="enterPress($event)">
  <div class="header">
    <img class="logo" src="./assets/images/FLogo.svg" >
  <div class="side-button">
    <div class="translate-menu" [matMenuTriggerFor]="menu">
      <img src="./assets/icons/language.svg">
      <span>{{ translationService.default.label }}</span>
    </div>
    <button class="back-button" [routerLink]="'/dashboard'"> <img style="margin-right: 5px;" src="./assets/icons/arrow-left.svg"> {{ 'back' | translate }}</button>
  </div>
  <mat-menu #menu="matMenu" >
    <ng-container *ngFor="let language of translationService.availabeLanguages">
      <button mat-menu-item (click)="translationService.switchLanguage(language.value)">{{ language.label }}</button>
    </ng-container>
  </mat-menu>
  </div>
  <div class="card" [formGroup]="singInForm">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="center-text card-title">
      <p class="title">{{'sign_in' | translate}}</p>
      <span>{{'Welcome back! Please sign in to continue.' | translate}}</span>
    </div>
    <div class="bold-text label">{{ "email_address" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="email" formControlName="provider" matInput [placeholder]="'email_address' | translate">
      <mat-error *ngIf="isInvalid(singInForm,'provider')">{{ "email_address" | translate }} {{ getErrorMessage(singInForm,'provider')  | translate }}</mat-error>
    </mat-form-field>

    <div class="bold-text label">{{ "password" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="password" #password formControlName="password" matInput [placeholder]="'password' | translate">
      <img src="./assets/icons/eye.svg" class="seen" *ngIf="seenPassword" (click)="changeSeen()">
      <img src="./assets/icons/eye-slash.svg" class="seen" *ngIf="!seenPassword" (click)="changeSeen()">
      <mat-error *ngIf="isInvalid(singInForm,'password')">{{ "password" | translate }} {{ getErrorMessage(singInForm,'password') | translate }}</mat-error>
    </mat-form-field>
    <span class="primary-color pointer" routerLink="/send-email">{{'forgot_password_?' | translate}}</span>
    <button class="signin-button" [disabled]="loading"
    (click)="login()">{{ 'sign_in' | translate }}</button>
  </div>
</div>
<span class="bottom-center">© Copyright 2024, All Rights Reserved.</span>

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, retry, tap, timer } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private isRefreshing = false;


  constructor(private router: Router, private authService: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('http')) {
      if (!this.authService.getUser() && request.url.includes('company-profile')) {
        request = request.clone({
          setHeaders: {
            "Authorization": "Bearer " + `${this.authService.userLogin.accessToken}`,
            "x-tenant-id": this.authService.userLogin?.businessOwner?.tenant?.id || ""
          },
        });
      }
      // http request
      if (this.authService.getUser() && !this.isRefreshing) {
        request = request.clone({
          setHeaders: {
            "Authorization": "Bearer " + `${this.authService.getAuthToken()}`,
            "x-tenant-id": this.authService.getTenantId().toString()
          },
        });
      }
      if (this.authService.getUser() && this.isRefreshing) {
        this.isRefreshing=false
        request = request.clone({ 
          setHeaders: {
            "Authorization": "Bearer " + `${this.authService.getRefreshToken()}`,
            "x-tenant-id": this.authService.getTenantId().toString()
          },
        });
      }
      if(request.url.includes('redirect')){
        request = request.clone({
          setHeaders: {
            "Authorization": "Bearer " + `${this.authService.apiKey}`,
          },
        });
      }
      return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // handle un-success response
          if (event.body?.status == false) {
            const errors = event.body?.errors;
            let message = event.body?.message;
            // try get message from validation error
            if (Array.isArray(errors) && errors.length > 0) {
              const error = errors[0];
              if (Array.isArray(error)) {
                message = error[0];
              }
            }
            const error = {
              status: event.body?.status_code,
              error: message,
              statusText: message,
              message,
              statusCode: event.body?.status_code,
            };
            throw new HttpErrorResponse({ error });
          }
        }
      })).pipe(catchError((error: HttpErrorResponse) => { throw this.handleError(error,request, next) }));
    } else {
      // assets request
      return next.handle(request);
    }
  }

  private handleError(response: HttpErrorResponse,request: HttpRequest<any>, next: HttpHandler): HttpErrorResponse {
    const statusCode = response.status;
    if (statusCode === 401 && this.authService.getUser() ) {
      this.isRefreshing=true
      this.authService.sendRefreshToken().subscribe({
        next:(res)=>{
            return next.handle(request).pipe(retry({count: 2, delay: this.shouldRetry}))
        },
        error:(err)=>{
          this.router.navigateByUrl(`/login`);
        }
      })
    }
    const errorResponse = response.error ?? response;
    return new HttpErrorResponse({
      error: errorResponse?.message,
      status: errorResponse?.statusCode,
      statusText: errorResponse?.message ?? errorResponse?.message,
    });
  }
  shouldRetry(error: HttpErrorResponse){
    if(error.status == 401){
      return timer(1000);
    }
    throw error;
  }
}





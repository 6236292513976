<div class="container" (keyup)="enterPress($event)">
  <div class="header">
    <img class="logo" src="./assets/images/FLogo.svg">
    <div class="side-button">
      <div class="translate-menu" [matMenuTriggerFor]="menu">
        <img src="./assets/icons/language.svg">
        <span>{{ translationService.default.label }}</span>
      </div>
      <button class="back-button" [routerLink]="'/dashboard'"> <img style="margin-right: 5px;"
          src="./assets/icons/arrow-left.svg"> {{ 'back' | translate }}</button>
    </div>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let language of translationService.availabeLanguages">
        <button mat-menu-item (click)="translationService.switchLanguage(language.value)">{{ language.label }}</button>
      </ng-container>
    </mat-menu>
  </div>
  <div class="card-container" *ngIf="showForm">
  <div class="card" [formGroup]="companyInfoForm">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="center-text card-title">
      <p class="title">{{'set_up_your_company_profile' | translate}}</p>
      <span>{{'enter_your_company_details' | translate}}</span>
    </div>
    <div class="bold-text label">{{ "company_name" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="text" formControlName="companyName" matInput [placeholder]="'company_name' | translate">
      <mat-error *ngIf="isInvalid(companyInfoForm,'companyName')">{{ "company_name" | translate }} {{
        getErrorMessage(companyInfoForm,'companyName') | translate }}</mat-error>
    </mat-form-field>

    <div class="bold-text label">{{ "country" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="text" formControlName="companyLocation" matInput [placeholder]="'country' | translate">
      <mat-error *ngIf="isInvalid(companyInfoForm,'companyLocation')">{{ "country" | translate }} {{
        getErrorMessage(companyInfoForm,'companyLocation') | translate }}</mat-error>
    </mat-form-field>

    <div class="bold-text label">{{ "state/province" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="text" formControlName="state" matInput [placeholder]="'state/province' | translate">
      <mat-error *ngIf="isInvalid(companyInfoForm,'state')">{{ "state/province" | translate }} {{
        getErrorMessage(companyInfoForm,'state') | translate }}</mat-error>
    </mat-form-field>
    <div class="half-grid">
      <div>
        <div class="bold-text label">{{ "currency" | translate }}</div>
        <mat-form-field class="full-width">
          <mat-select [formControlName]="'currency'" placeholder="{{'currency' | translate}}">
            <mat-option *ngFor="let currency of allCurrency" [value]="currency.value">{{currency.label|
              translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="isInvalid(companyInfoForm,'currency')">{{ "currency" | translate }} {{
            getErrorMessage(companyInfoForm,'currency') | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <div class="bold-text label">{{ "language" | translate }}</div>
        <mat-form-field class="full-width">
          <mat-select [formControlName]="'language'" placeholder="{{'language' | translate}}">
            <mat-option value="en">{{'English' | translate}}</mat-option>
            <mat-option value="ar">{{'Arabic' | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="isInvalid(companyInfoForm,'language')">{{ "language" | translate }} {{
            getErrorMessage(companyInfoForm,'language') | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="year-grid">
      <div>
    <div class="bold-text label">{{ "fiscal_year" | translate }}</div>
    <mat-form-field class="full-width">
      <mat-select [formControlName]="'fiscalYear'" placeholder="{{'fiscal_year' | translate}}">
        <mat-option *ngFor="let month of months" [value]="month">{{month | translate}}</mat-option>
      </mat-select>
      <mat-error *ngIf="isInvalid(companyInfoForm,'fiscalYear')">{{ "fiscal_year" | translate }} {{
        getErrorMessage(companyInfoForm,'fiscalYear') | translate }}</mat-error>
    </mat-form-field>
  </div>
  <div>
    <div class="bold-text label">{{ "startDate" | translate }}</div>
    <mat-form-field class="full-width">
      <mat-select [formControlName]="'startDate'" placeholder="{{'startDate' | translate}}">
        <mat-option *ngFor="let opt of getStartDay()" [value]="opt">{{opt}}</mat-option>
      </mat-select>
      <mat-error *ngIf="isInvalid(companyInfoForm,'startDate')">{{ "startDate" | translate }} {{
        getErrorMessage(companyInfoForm,'startDate') | translate }}</mat-error>
    </mat-form-field>
  </div>
  </div>
  <div class="bold-text label">{{ "timeZone" | translate }}</div>
  <mat-form-field class="full-width">
    <mat-select [formControlName]="'timeZone'" placeholder="{{'timeZone' | translate}}">
      <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.value">{{timeZone.label}}</mat-option>
    </mat-select>
    <mat-error *ngIf="isInvalid(companyInfoForm,'timeZone')">{{ "timeZone" | translate }} {{
      getErrorMessage(companyInfoForm,'timeZone') | translate }}</mat-error>
  </mat-form-field>

  <div class="medical-number-grid">
    <div>
  <div class="bold-text label">{{ "medical_number" | translate }}</div>
  <mat-form-field class="full-width">
    <input type="text" matInput [formControlName]="'prefix'"
        placeholder="{{'prefix' | translate}}">
    <mat-error>{{'prefix' | translate }} {{ getErrorMessage(companyInfoForm, "prefix")
        }}</mat-error>
</mat-form-field>
</div>
<div>
  <div class="bold-text label mt-55"></div>
  <mat-form-field class="full-width">
    <input type="text" matInput [formControlName]="'startingNumber'"
        placeholder="{{'starting_number' | translate}}">
    <mat-error>{{'starting_number' | translate }} {{ getErrorMessage(companyInfoForm, "startingNumber")
        }}</mat-error>
</mat-form-field>
</div>
</div>
    <button class="send-button" [disabled]="loading" (click)="sendInfo()">{{ 'get_started' | translate }}</button>
  </div>
</div>
<br>
<div class="center-text">© Copyright 2024, All Rights Reserved.</div>
<br>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { PageNotFoundAuthComponent } from './core/page-not-found/page-not-found-auth.component';
import { RedirectComponent } from './core/redirect-page/redirect.component';
import { SendEmailComponent } from './core/reset-password/send-email/send-email.component';
import { NewPasswordComponent } from './core/reset-password/new-password/new-password.component';
import { VerifyComponent } from './core/reset-password/verify/verify.component';
import { OTPComponent } from './core/otp/otp.component';
import { OTPGuard } from './shared/guards/otp.guard';
import { VerifyGuard } from './shared/guards/verify.guard';
import { ResetPasswordGuard } from './shared/guards/reset-password.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/statistics',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => LoginComponent,
    data: {
      title: 'Login'
    },
  },
  {
    path: 'otp',
    loadComponent: () => OTPComponent,
    canActivate: [OTPGuard],
    data: {
      title: 'verify'
    },
  },
  {
    path: 'send-email',
    loadComponent: () => SendEmailComponent,
    data: {
      title: 'send_email'
    },
  },
  {
    path: 'verify',
    loadComponent: () => VerifyComponent,
    canActivate: [VerifyGuard],
    data: {
      title: 'verify'
    },
  },
  {
    path: 'reset-password',
    loadComponent: () => NewPasswordComponent,
    canActivate: [ResetPasswordGuard],
    data: {
      title: 'reset_password'
    },
  },
  {
    path: 'redirect/:id',
    loadComponent: () => RedirectComponent,
    data: {
      title: 'redirect'
    },
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./core/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  }, 
  {
    path: '**',
    loadComponent: () => PageNotFoundAuthComponent,
    data: {
      title: 'Not Found'
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../../modules/shared.module';
import { ThemeService } from '../../services/theme.service';
import { LottieModule } from 'ngx-lottie';
@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, SharedModule,LottieModule],
})
export class AppLoaderComponent implements OnInit {
  options: any = {    
    path: './assets/icons/loader.json'  
  };  

  constructor(public themeService: ThemeService) { }

  ngOnInit(): void { }
}

<div class="container" (keyup)="enterPress($event)">
  <div class="header">
    <img class="logo" src="./assets/images/FLogo.svg" >
  <div class="side-button">
    <div class="translate-menu" [matMenuTriggerFor]="menu">
      <img src="./assets/icons/language.svg">
      <span>{{ translationService.default.label }}</span>
    </div>
    <button class="back-button" [routerLink]="'/dashboard'"> <img style="margin-right: 5px;" src="./assets/icons/arrow-left.svg"> {{ 'back' | translate }}</button>
  </div>
  <mat-menu #menu="matMenu" >
    <ng-container *ngFor="let language of translationService.availabeLanguages">
      <button mat-menu-item (click)="translationService.switchLanguage(language.value)">{{ language.label }}</button>
    </ng-container>
  </mat-menu>
  </div>
  <div class="card" [formGroup]="resetPasswordForm">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="center-text card-title">
      <p class="title">{{'reset_password' | translate}}</p>
      <span>{{'enter_the_new_password.' | translate}}</span>
    </div>
    <div class="bold-text label">{{ "new_password" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="password" #password formControlName="newPassword" matInput [placeholder]="'new_password' | translate">
      <img src="./assets/icons/eye.svg" class="seen" *ngIf="seenPassword" (click)="changeSeen()">
      <img src="./assets/icons/eye-slash.svg" class="seen" *ngIf="!seenPassword" (click)="changeSeen()">
      <mat-error *ngIf="isInvalid(resetPasswordForm,'newPassword')">{{ "new_password" | translate }} {{ getErrorMessage(resetPasswordForm,'newPassword')  | translate }}</mat-error>
    </mat-form-field>

    <div class="bold-text label">{{ "confirm_password" | translate }}</div>
    <mat-form-field class="full-width">
      <input type="password" #confirmPassword formControlName="confirmPassword" matInput [placeholder]="'confirm_password' | translate">
      <img src="./assets/icons/eye.svg"  class="seen" *ngIf="seenConfirmPassword" (click)="changeConfirmSeen()">
      <img src="./assets/icons/eye-slash.svg" class="seen" *ngIf="!seenConfirmPassword" (click)="changeConfirmSeen()">
      <mat-error *ngIf="isInvalid(resetPasswordForm,'confirmPassword')">{{ "confirm_password" | translate }} {{ getErrorMessage(resetPasswordForm,'confirmPassword') | translate }}</mat-error>
    </mat-form-field>
    <button class="send-button" [disabled]="loading"
    (click)="login()">{{ 'submit' | translate }}</button>
  </div>
</div>
<span class="bottom-center">© Copyright 2024, All Rights Reserved.</span>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TOAST_TYPE, ToastService } from 'src/app/shared/services/toast.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { v4 as uuidv4 } from 'uuid';
import { MatMenuModule } from '@angular/material/menu';
import { TranslationService } from 'src/app/shared/services/translation.service';
@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    SharedModule,
  ],
})
export class SendEmailComponent implements OnInit {
  public sendEmailForm!: FormGroup;
  public loading: boolean = false;
  constructor(
    private toastService : ToastService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private messagingService: MessagingService,
    public themeService: ThemeService,
    public translationService : TranslationService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }
  /**
   * initailize login form
   */
  private initializeForm(): void {
    this.sendEmailForm = this.formBuilder.group({
      email: [null, [Validators.required,Validators.email]],
    });
  }

  /**
   * send credentials to server
   */
  public sendEmail() {
    if(this.sendEmailForm.invalid){
      this.sendEmailForm.markAllAsTouched()
      return;
    }
    this.loading = true;
    this.authService.sendCodeToResetPassword(this.sendEmailForm.value).subscribe({
      next: () => {
        this.authService.resetPasswordEmail = this.sendEmailForm.value.email
        this.router.navigate(['/verify'])
      },
      error: (error) => {
        this.loading = false;
        this.toastService.addToasts({message:(error.message || error) ,type:TOAST_TYPE.DANGER,id:Date.now()})
      },
    });
  }
  enterPress(event:KeyboardEvent){
    if(event.key=="Enter"){
     this.sendEmail() 
    }
   }
  isInvalid(formGroup: FormGroup, formControlName: string) {
    return formGroup.get(formControlName)?.invalid &&
      formGroup.get(formControlName)?.touched
  }

  getErrorMessage(formGroup: FormGroup, controlName: string) {
    let errors: any = [];
    const errorsObj : any = formGroup.get(controlName)!.errors;
    Object.keys(formGroup.get(controlName)?.errors || []).forEach((key) => {
      if (key == 'required') {
        errors.push('required');
      }
      else if(key == 'email'){
        errors.push('is_invalid_email')
      }
    });
    return errors.join(', ');
  }
}
